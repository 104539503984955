export enum LOCAL_STORAGE {
  SELECTED_LANGUAGE = 'selectedLanguage',
  THEME_MENU_MODE_LS_KEY = 'kt_theme_mode_menu',
  SIDEBAR_MINIMIZE_STATE = 'sidebarMinimizeState',
  OFFERS_FILTERS = 'offersFilters',
  TOKEN_EXPIRES_AT = 'id_token_expires_at',
  TOKEN_STORED_AT = 'id_token_stored_at',
  LOCATIONS_FILTERS = 'locationsFilters',
  CUSTOMERS_FILTERS = 'customersFilters',
  NAVIGATION_URL = 'firstNavigationUrl',
}

export const AUTH_STORAGE_ITEMS: string[] = [
  'access_token',
  'access_token_stored_at',
  'expires_at',
  'granted_scopes',
  'id_token',
  'id_token_claims_obj',
  'id_token_expires_at',
  'id_token_stored_at',
  'nonce',
  'PKCE_verifier',
  'refresh_token',
  'session_state',
];
